/* eslint-disable max-len */
import styles from './HistoryIcon.module.scss';

const HistoryIcon = () => {
  return (
  <svg className={styles.icon} viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 14.9717C30.0153 23.2362 23.2754 29.9942 15.0109 30C11.4414 30.0025 8.16271 28.7583 5.58616 26.6788C4.91619 26.138 4.8661 25.1339 5.47493 24.5251L6.15641 23.8436C6.67711 23.3229 7.5084 23.266 8.0853 23.7236C9.98358 25.23 12.3859 26.1291 15 26.1291C21.1515 26.1291 26.129 21.1506 26.129 15C26.129 8.8485 21.1505 3.87098 15 3.87098C12.0475 3.87098 9.36598 5.0183 7.37491 6.89106L10.4447 9.96087C11.0544 10.5705 10.6226 11.6129 9.76046 11.6129H0.967741C0.433246 11.6129 0 11.1797 0 10.6452V1.85244C0 0.990304 1.04238 0.558509 1.65205 1.16813L4.63826 4.15434C7.33118 1.58081 10.981 0 15 0C23.2748 0 29.9847 6.70042 30 14.9717ZM19.0577 19.7368L19.6519 18.9729C20.1441 18.3401 20.0301 17.428 19.3972 16.9359L16.9355 15.0211V8.70969C16.9355 7.90798 16.2856 7.25808 15.4839 7.25808H14.5161C13.7144 7.25808 13.0645 7.90798 13.0645 8.70969V16.9144L17.0207 19.9915C17.6535 20.4836 18.5655 20.3697 19.0577 19.7368Z" fill="white"/>
  </svg>
  );
};

export default HistoryIcon;
