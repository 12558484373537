/* eslint-disable max-len */
const YouTubeIcon = () => {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0644 0H10.1756C11.2032 0.00375023 16.4098 0.0412528 17.8136 0.418776C18.238 0.533994 18.6247 0.758576 18.9351 1.07008C19.2455 1.38158 19.4688 1.76909 19.5825 2.19389C19.7087 2.66892 19.7975 3.29771 19.8575 3.9465L19.87 4.07651L19.8975 4.40153L19.9075 4.53153C19.9888 5.6741 19.9988 6.74417 20 6.97794V7.07169C19.9988 7.31421 19.9875 8.45678 19.8975 9.64685L19.8875 9.77811L19.8762 9.90812C19.8137 10.6232 19.7212 11.3332 19.5825 11.8557C19.4691 12.2807 19.246 12.6684 18.9355 12.98C18.6251 13.2915 18.2382 13.516 17.8136 13.6309C16.3635 14.0209 10.8519 14.0484 10.0881 14.0496H9.91062C9.52435 14.0496 7.92675 14.0421 6.25164 13.9846L6.03913 13.9771L5.93037 13.9721L5.71661 13.9634L5.50284 13.9546C4.11526 13.8934 2.79392 13.7946 2.18514 13.6296C1.76072 13.5148 1.37392 13.2906 1.06347 12.9792C0.753027 12.6679 0.529842 12.2805 0.416276 11.8557C0.277517 11.3345 0.185012 10.6232 0.122508 9.90812L0.112507 9.77686L0.102506 9.64685C0.0408121 8.7998 0.00662525 7.95097 0 7.10169L0 6.94793C0.00250016 6.67917 0.0125008 5.75036 0.080005 4.7253L0.0887556 4.59654L0.0925058 4.53153L0.102506 4.40153L0.130008 4.07651L0.142509 3.9465C0.202513 3.29771 0.291268 2.66767 0.417526 2.19389C0.5309 1.76892 0.754 1.38122 1.06446 1.06967C1.37492 0.758115 1.76182 0.533649 2.18639 0.418776C2.79517 0.256266 4.11651 0.15626 5.50409 0.0937558L5.71661 0.0850053L5.93162 0.077505L6.03913 0.0737548L6.25289 0.0650043C7.4426 0.0267201 8.6328 0.00546642 9.82311 0.00125014H10.0644V0ZM8.0005 4.01275V10.0356L13.1971 7.02544L8.0005 4.01275Z" fill="white"/>
    </svg>

  );
};

export default YouTubeIcon;
